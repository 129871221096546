import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <h1>Get in Touch</h1>
      <p>Email: kntreadway (at) gmail (dot) com </p>
      <p>
        Twitter: <a href="https://www.twitter.com/kntreadway">@kntreadway</a>
      </p>
    </Layout>
  )
}

export default ContactPage
